import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Box, Button, Container, Grid, Grid2, Typography, useMediaQuery} from '@mui/material';
import {
    Employee,
    FinalizePayStubMutation,
    GetPayStubQuery,
    PayStub,
    PayStubWithDeductions,
    SaveEmployeeToPayStubMutation,
    SaveEmployerToPayStubMutation,
    TotalAmounts,
    UpdatePayStubBasedOnPreviousPayStubMutation,
    useFinalizePayStubMutation,
    useGetDeductionTypesQuery,
    useGetPayStubLazyQuery,
    useSaveEmployeeToPayStubMutation,
    useSaveEmployerToPayStubMutation,
    useUpdatePayStubBasedOnPreviousPayStubMutation,
} from "../graphql/generated/graphql";
import {StyledPaper} from "../components/PayStub/payStubStyles";
import {useNavigate, useParams} from "react-router-dom";
import EditableIncomeLines from "../components/PayStub/EditableIncomeLines";
import Deductions from "../components/PayStub/Deductions";
import EmployerDisplayEdit from "../components/Employer/EmployerDisplayEdit";
import EmployeeDisplayEdit from "../components/Employee/EmployeeDisplayEdit";
import PayDateDisplayEdit from "../components/PayStub/PayDateDisplayEdit";
import PayPeriodDisplayEdit from "../components/PayStub/PayPeriodDisplayEdit";
import useAnonCookie from "../security/useAnonCookie";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import {
    DATE_FORMAT,
    getDateRangeFromStrings,
    getStringFromDate,
    getTodayAsString,
    getYearFromNullableDateOrString
} from "../Utils/dateFormatter";
import ErrorBoundary from "../common/ErrorBoundary";
import usePayStubIdManager from "../components/PayStub/usePayStubIdManager";
import {ApolloError, FetchResult} from "@apollo/client";
import useSystemNotices from "../Utils/useSystemNotices";
import PreviousPayStubDisplayEdit from "../components/PayStub/PreviousPayStubDisplayEdit";
import PayStubPreview from "../components/PayStub/PayStubPreview";
import PayStubDownload from "../components/PayStub/PayStubDownload";
import dayjs, {Dayjs} from "dayjs";
import {DateRange} from "@mui/x-date-pickers-pro";
import ErrorDisplay from "../common/ErrorDisplay";
import {MY_ACCOUNT} from "../common/AppLinks";
import {getNumber} from "../Utils/stringMath";
import SuccessMessageDisplay from "../common/SuccessMessageDisplay";
import theme from "../theme";
import {useAuth0} from "@auth0/auth0-react";
import AppPreferenceDialog from "../components/AppPreferenceDialog";
import {assumedUserContext} from "../components/User/AssumedUserContext";
import ClassicViewLink from "../components/PayStub/ClassicViewLink";
import ResetPayStubLink from "../components/PayStub/ResetPayStubLink";
import VacationSummaryDisplayEdit from "../components/PayStub/VacationSummaryDisplayEdit";
import PayStubTotals from "../components/PayStub/SummaryBox";
import CreateNextPayStubButton from "../components/PayStub/CreateNextPayStubButton";
import PopUpErrorDisplay from "../common/PopUpErrorDisplay";


export type ParamsType = {
    paramsPayStubId: string;
}

const getEmptyPayStub = (): PayStub => {
    return {
        employeeId: '0',
        employerCpp: '00.00',
        employerEi: '00.00',
        employerId: '0',
        logo: '',
        periodStart: getStringFromDate(dayjs().subtract(2, 'week'), DATE_FORMAT),
        periodEnd: getTodayAsString(),
        id: 0,
        currentPeriodIncomeId: '',
        priorPeriodsIncomeId: ''
    }
}


function shouldLoadPayStubData(payStubLoading: boolean,
                               payStubData: GetPayStubQuery | undefined,
                               payStubId: number | undefined,
                               payStubDataError: ApolloError | undefined,
                               reloadPayStub: boolean
): boolean {
    if (!payStubId) {
        return false;
    }
    if (payStubLoading || !!payStubDataError) {
        return false;
    }
    if (!payStubData) {
        return true;
    }
    if (reloadPayStub) {
        return true;
    }
    return payStubData.payStub.payStub.id !== payStubId;
}

const PayStubDisplayEdit: React.FC = () => {
    const {paramsPayStubId} = useParams<ParamsType>();
    const [isFinalized, setIsFinalized] = useState<boolean>(false);
    const [payStub, setPayStub] = useState<PayStub>(getEmptyPayStub());
    const [totalAmounts, setTotalAmounts] = useState<TotalAmounts | undefined | null>();
    const [payDate, setPayDate] = useState<string>(getTodayAsString());
    const [error, setError] = useState<string>();
    const [reloadIncomeItems, setReloadIncomeItems] = useState(false);
    const [reloadDeductions, setReloadDeductions] = useState(false);
    const [reloadTotals, setReloadTotals] = useState(false);
    const [checkForCalculateNeeded, setCheckForCalculateNeeded] = useState(false);
    const [previousPayStubId, setPreviousPayStubId] = useState<number | undefined | null>(undefined);
    const [showPayStubPreview, setShowPayStubPreview] = useState(false);
    const [showPayStubDownload, setShowPayStubDownload] = useState(false);
    const [finalizedErrorMessage, setFinalizedErrorMessage] = useState<string | null | undefined>();
    const [finalizedSuccessMessage, setFinalizedSuccessMessage] = useState<string | null | undefined>();
    const [deductionsReady, setDeductionsReady] = useState<boolean>(false);
    const [clearDeductions, setClearDeductions] = useState(false);
    const [generalApolloError, setGeneralApolloError] = useState<ApolloError>();
    const [generalStringError, setGeneralStringError] = useState<string>();
    const [showMyAccountButton, setShowMyAccountButton] = useState(false);
    const [checkAppPreference, setCheckAppPreference] = useState<boolean>(false);
    const {assumedUser} = useContext(assumedUserContext);
    const [reloadPayStub, setReloadPayStub] = useState(false);

    const navigate = useNavigate();
    const {isAuthenticated, loginWithPopup} = useAuth0();
    const {sendNotice} = useSystemNotices();
    const {getAnonUserId} = useAnonCookie();


    const [
        getPayStub,
        {
            data: payStubData,
            error: payStubDataError,
            loading: payStubLoading

        }] = useGetPayStubLazyQuery({
        fetchPolicy: "no-cache"
    });

    const {
        getPayStubId,
        createNewPayStub,
        createNewPayStubLoading,
        createNewPayStubError
    } = usePayStubIdManager();

    const {
        data: deductionTypesData,
        loading: deductionTypesLoading,
        error: deductionTypesError
    } = useGetDeductionTypesQuery({
        variables: {
            anonUserId: getAnonUserId(),
            userId: assumedUser.id
        }
    });

    const [
        saveEmployerToPayStub,
        {
            loading: saveEmployerToPayStubLoading,
            error: saveEmployerToPayStubError
        }
    ] = useSaveEmployerToPayStubMutation();


    const [
        finalizePayStub,
        {
            loading: finalizePayStubLoading,
            error: finalizePayStubError
        }
    ] = useFinalizePayStubMutation();

    const [
        saveEmployeeToPayStub,
        {
            loading: saveEmployeeToPayStubLoading,
            error: saveEmployeeToPayStubError
        }
    ] = useSaveEmployeeToPayStubMutation();

    const [
        updatePayStubBasedOnPrevious,
        {
            loading: updatePayStubLoading,
            error: updatePayStubError
        }
    ] = useUpdatePayStubBasedOnPreviousPayStubMutation();

    function removeEmployeeIdFromPayStub() {
        setPayStub((prevState: PayStub) => {
            return {
                ...prevState,
                employeeId: '0'
            }
        });
    }

    const handleEmployeeAddedEdited = async (employee: Employee) => {
        // clearing out the pay stub data while the employee is updated prevents other things like the PreviousPayStubDisplay from rendering when we don't want them to.
        setPayStub(getEmptyPayStub());
        setFinalizedErrorMessage(undefined);
        setClearDeductions(true);
        let payStubId = getPayStubId();
        try {
            const saveResult: FetchResult<SaveEmployeeToPayStubMutation> = await saveEmployeeToPayStub({
                variables: {
                    payStubId: payStubId,
                    anonUserId: getAnonUserId(),
                    employeeId: employee.id.toString(),
                    userId: assumedUser.id
                }
            })
            // saveEmployeeToPayStub will create a pay stub shell and return a new pay stub id if one has not been created.
            let savedPayStub = saveResult.data?.saveEmployeeToPayStub;
            setClearDeductions(false);
            if (!!savedPayStub) {
                setPayStubData(savedPayStub);
                setCheckForCalculateNeeded(true);
            }
            if (!savedPayStub || !savedPayStub.payStub.id) {
                setError("Error saving employee on pay stub. Please try again or contact support.");
            }
        } catch (error: any) {
            sendNotice(`error saving employee to pay stub: ${JSON.stringify(error, null, 2)}`);
            console.error(error);
            setError("Error saving employee on pay stub. Please try again or contact support.");
            removeEmployeeIdFromPayStub();
        }
    }

    const clearEmployer = useCallback(() => {
        setPayStub((prevState: PayStub) => {
            return {
                ...prevState,
                employerId: "0",
            }
        });
    }, [setPayStub]);

    const handleEmployerChange = async (employerId: number) => {
        setFinalizedErrorMessage(undefined);
        let payStubId = getPayStubId();
        if (!payStubId) {
            payStubId = await createNewPayStub();
        }
        if (!payStubId) {
            setError("Unable to initiate new pay stub. Please try again or contact support.");
            return;
        }
        saveEmployerToPayStub({
            variables: {
                payStubId: payStubId,
                anonUserId: getAnonUserId(),
                employerId: employerId,
            }
        })
            .then((saveResult: FetchResult<SaveEmployerToPayStubMutation>) => {
                if (!!saveResult.data?.saveEmployerToPayStub) {
                    setPayStub((prevState: PayStub) => {
                        return {
                            ...prevState,
                            employerId: employerId.toString(),
                        }
                    });
                } else {
                    setError("Error saving employer on pay stub. Please try again or contact support.");
                }
            });

    }

    const payStubId = getPayStubId();

    const recalculateDeductionsIfYearHasChanged = useCallback((newPayDate: string) => {
        if (!newPayDate) {
            return;
        }
        let newYear = getYearFromNullableDateOrString(newPayDate);
        let oldYear = getYearFromNullableDateOrString(payDate);
        if (newYear !== oldYear) {
            setCheckForCalculateNeeded(true);
        }
    }, [payDate]);

    const navigateIfNewPayStubId = useCallback((newPayStubId: number) => {
        const payStubId = getPayStubId();
        if (!payStubId || newPayStubId !== payStubId) {
            navigate(`/pay-stub/${newPayStubId}`);
        }
    }, [getPayStubId, navigate]);

    const updatePayPeriod = useCallback((start: string, end: string, newPayStubId: number) => {
        const payStubId = getPayStubId();
        if (!payStubId || newPayStubId !== payStubId) {
            navigate(`/pay-stub/${newPayStubId}`);
            return;
        }
        if (!start || !end) {
            return;
        }
        setPayStub(prevState => {
            return {
                ...prevState,
                periodStart: start,
                periodEnd: end
            }
        });
    }, [getPayStubId, navigate, setPayStub]);


    const setPayStubData = useCallback((payStubData: PayStubWithDeductions) => {
        if (payStubData.payStub.id !== payStubId) {
            navigate(`/pay-stub/${payStubData.payStub.id}`);
            return;
        }
        setFinalizedErrorMessage(undefined);
        setFinalizedSuccessMessage(undefined);
        setPreviousPayStubId(payStubData.previousPayStubId);
        setPayDate(payStubData.payDate);
        setPayStub(payStubData.payStub);
        setReloadIncomeItems(true);
        setReloadDeductions(true);
        setIsFinalized(payStubData.finalized);
        setTotalAmounts(payStubData.totalAmounts);
    }, [setPayStub, setPreviousPayStubId, setReloadIncomeItems, navigate, payStubId]);


    const clearPayStubData = useCallback(() => {
        setPayStub(getEmptyPayStub());
        setPreviousPayStubId(undefined);
        setIsFinalized(false);
        setTotalAmounts(undefined);
        setClearDeductions(true);
        setFinalizedErrorMessage(undefined);
        setFinalizedSuccessMessage(undefined);
        setGeneralStringError(undefined);
        setGeneralApolloError(undefined);
    }, []);

    useEffect(() => {
        if (!!payStubId && shouldLoadPayStubData(payStubLoading, payStubData, payStubId, payStubDataError, reloadPayStub)) {
            clearPayStubData();
            setReloadPayStub(false);
            let anonUserId = getAnonUserId();
            if (!anonUserId) {
                sendNotice("anonUserId is empty in load pay stub useEffect on PayStubDisplayEdit");
            }
            getPayStub({variables: {id: payStubId, anonUserId: anonUserId}})
                .then((getPayStubResult: FetchResult<GetPayStubQuery>) => {
                    if (getPayStubResult.data?.payStub) {
                        setPayStubData(getPayStubResult.data.payStub);
                        setCheckForCalculateNeeded(true);
                    }
                })
                .catch(error => {
                    sendNotice(`Error in PayStubDisplayEdit from getPayStub call: ${JSON.stringify(error, null, 2)}`)
                });
        }
        if (!payStubId && !!payStubData) {
            clearPayStubData();
        }
    }, [sendNotice, clearPayStubData, payStubLoading, payStubData, payStubId, payStubDataError, getAnonUserId, getPayStub, setPayStubData, reloadPayStub]);

    useEffect(() => {
        if (!!payStubId && reloadTotals) {
            let anonUserId = getAnonUserId();
            if (!anonUserId) {
                sendNotice("anonUserId is empty in reloadTotals useEffect on PayStubDisplayEdit");
            }
            getPayStub({variables: {id: payStubId, anonUserId: anonUserId}})
                .then((getPayStubResult: FetchResult<GetPayStubQuery>) => {
                    let payStubData = getPayStubResult.data?.payStub;
                    if (payStubData) {
                        setTotalAmounts(payStubData.totalAmounts);
                    }
                    setReloadTotals(false);
                });
        }
    }, [sendNotice, reloadTotals, getAnonUserId, getPayStub, payStubId]);

    useEffect(() => {
        if (!!deductionTypesError) {
            setGeneralApolloError(deductionTypesError);
        }
    }, [deductionTypesError, deductionTypesLoading]);


    const updatePreviousPayStubId = useCallback((newPreviousPayStubId: number | undefined, nextPayDate: string | undefined) => {

        setPreviousPayStubId(newPreviousPayStubId);
        if (!!payStubId && !!nextPayDate && !!newPreviousPayStubId) {
            updatePayStubBasedOnPrevious({
                variables: {
                    payStubId: payStubId,
                    previousPayStubId: newPreviousPayStubId,
                    anonUserId: getAnonUserId(),
                    nextPayDate: nextPayDate
                }
            })
                .then((updatedPayStub: FetchResult<UpdatePayStubBasedOnPreviousPayStubMutation>) => {
                    if (!!updatedPayStub && !!updatedPayStub.data?.updatePayStubBasedOnPreviousPayStub.payStub) {
                        setPayStubData(updatedPayStub.data.updatePayStubBasedOnPreviousPayStub);
                        setCheckForCalculateNeeded(true);
                        setReloadDeductions(true);
                    }
                });
        }
    }, [setPreviousPayStubId, updatePayStubBasedOnPrevious, payStubId, getAnonUserId, setPayStubData]);


    const getFinalizeValidationMessages = useCallback(() => {
        let messages = [];
        if (!getNumber(payStub.employeeId)) {
            messages.push("Please add an employee.");
            return messages; // if there is no employee, we want the user to focus on that first.
        }
        if (!totalAmounts?.totalCurrentGross || getNumber(totalAmounts?.totalCurrentGross) <= 0) {
            messages.push("Please add at least one income line.");
        }
        if (!getNumber(payStub.employerId)) {
            messages.push("Please add an employer.");
        }
        if (!deductionsReady) {
            messages.push("Please calculate or add your own deductions.");
        }
        return messages;
    }, [payStub.employeeId, payStub.employerId, totalAmounts?.totalCurrentGross, deductionsReady]);

    const finalizePayStubNow = useCallback(() => {
        setFinalizedErrorMessage(undefined);
        if (!isAuthenticated) {
            loginWithPopup()
                .then(result => {
                    console.log("result: ", JSON.stringify(result, null, 2));
                });
            return;
        }
        const finalizeValidationMessages = getFinalizeValidationMessages();
        if (finalizeValidationMessages.length > 0) {
            finalizeValidationMessages.unshift("Finalize requires a complete pay stub: ");
            setFinalizedErrorMessage(finalizeValidationMessages.join(" "));
            return;
        }

        if (!payStubId) {
            return;
        }
        finalizePayStub({
            variables: {
                payStubId: payStubId,
                anonUserId: getAnonUserId()
            }
        })
            .then((finalizeResult: FetchResult<FinalizePayStubMutation>) => {
                let data = finalizeResult.data;
                if (!data) {
                    sendNotice(`ACTION REQUIRED. No data returned from finalizePayStub endpoint for pay stub id: ${payStubId}`)
                    return;
                }
                let finalized = data.finalizePayStub.finalized;
                setIsFinalized(finalized);
                if (finalized) {
                    setFinalizedSuccessMessage(data.finalizePayStub.message);
                    setCheckAppPreference(true);
                } else {
                    setFinalizedErrorMessage(data.finalizePayStub.message);
                    setShowMyAccountButton(data.finalizePayStub.paymentRequired);
                }
            });
    }, [sendNotice, payStubId, finalizePayStub, getAnonUserId, getFinalizeValidationMessages, isAuthenticated, loginWithPopup]);


    let payStubExistsButNotSetYet = !!paramsPayStubId && !payStubId;

    const previewOrDownloadButtons = isFinalized
        ? <Button
            sx={{mt: 1, mr: 3, fontSize: '1.5rem'}}
            variant={'contained'}
            size={'large'}
            disabled={!payStub.id}
            onClick={() => setShowPayStubDownload(true)}
        >
            Download
        </Button>
        : <Button
            sx={{mt: 1, mr: 3, fontSize: '1.5rem'}}
            variant={'contained'}
            size={'large'}
            disabled={!payStub.id}
            onClick={() => setShowPayStubPreview(true)}
        >
            Preview
        </Button>
    ;

    let employeeIdAsNumber = Number(payStub.employeeId);
    const payStubYetToLoad = !!payStubId && !payStub.id;
    const payPeriod: DateRange<Dayjs> = getDateRangeFromStrings(payStub.periodStart, payStub.periodEnd);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const showResetButton = !!payStubId && !isFinalized && (!!getNumber(payStub.employerId) || !!getNumber(payStub.employeeId));
    const myAccountButton = <Grid2 container>
        <Grid2 size={12}>
            <Button
                variant={'contained'}
                onClick={() => window.location.href = MY_ACCOUNT}
            >
                Manage my account
            </Button>
        </Grid2>
    </Grid2>;
    return <>
        {
            checkAppPreference &&
            <AppPreferenceDialog
                closeAppPreferenceDialog={() => setCheckAppPreference(false)}
            />
        }
        <Container maxWidth={isSmallScreen ? false : 'xl'}>
            <StyledPaper elevation={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs style={{flexGrow: 1}}>
                        <ClassicViewLink
                            payStub={payStub}
                            calculateNeeded={!deductionsReady}
                        />
                    </Grid>
                    <Box flexGrow={1} display="flex" justifyContent="flex-end">
                        {
                            showResetButton &&
                            <ResetPayStubLink
                                payStubId={payStubId}
                            />
                        }
                    </Box>
                </Grid>
                <Box style={{backgroundColor: '#f4f4f4', padding: '16px', borderRadius: '0.5rem'}}>
                    <Grid container justifyContent="space-between" alignItems="flex-start" sx={{marginTop: 1}}>
                        <Grid item xs={12} sm={6}>
                            <EmployerDisplayEdit
                                employerId={getNumber(payStub.employerId)}
                                employerUpdated={handleEmployerChange}
                                isEditable={!isFinalized}
                                clearEmployer={clearEmployer}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} alignItems="flex-start" sx={{mt: {xs: 2, sm: 0}}}>
                            {
                                (!payStubYetToLoad && !payStubLoading) &&
                                <EmployeeDisplayEdit
                                    employeeId={employeeIdAsNumber}
                                    employeeAddedEdited={handleEmployeeAddedEdited}
                                    employeeSelected={employee => {
                                        setPreviousPayStubId(undefined);
                                        handleEmployeeAddedEdited(employee);
                                    }}
                                    isEditable={!isFinalized}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} md={6} sx={{textAlign: {xs: 'center', md: 'left'}}}>
                            {(saveEmployerToPayStubLoading || saveEmployerToPayStubError) &&
                                <LoadingErrorDisplay
                                    loading={saveEmployerToPayStubLoading}
                                    apolloError={saveEmployerToPayStubError}
                                />
                            }
                            {
                                (!!error || !!createNewPayStubError || createNewPayStubLoading || payStubLoading || !payStub || !!payStubDataError || payStubExistsButNotSetYet) &&
                                <LoadingErrorDisplay
                                    stringError={error}
                                    apolloError={createNewPayStubError || payStubDataError}
                                    loading={createNewPayStubLoading || payStubLoading || payStubExistsButNotSetYet || !payStub}
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md={6} sx={{textAlign: {xs: 'center', md: 'right'}, mt: {xs: 2, md: 0}}}>
                            {(saveEmployeeToPayStubLoading || saveEmployeeToPayStubError) &&
                                <LoadingErrorDisplay
                                    loading={saveEmployeeToPayStubLoading}
                                    apolloError={saveEmployeeToPayStubError}
                                />
                            }
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{my: 2, ml: {xs: 1, sm: 4}, mr: {xs: 1, sm: 2, md: 1}}}>
                    <Grid container flexDirection="row" alignItems="flex-end" justifyContent="space-between">
                        <Grid item xs={7}>
                            <PayPeriodDisplayEdit
                                isEditable={!isFinalized}
                                payPeriod={payPeriod}
                                updatePayPeriod={updatePayPeriod}
                            />
                        </Grid>
                        <Grid item xs={5} display="flex" justifyContent="flex-end" sx={{mt: {xs: 3, md: 0}}}>
                            <PayDateDisplayEdit
                                isEditable={!isFinalized}
                                savePayDate={(date, payStubId) => {
                                    navigateIfNewPayStubId(payStubId);
                                    recalculateDeductionsIfYearHasChanged(date);
                                    setPayDate(date);
                                }}
                                date={payDate}
                                setApolloError={setGeneralApolloError}
                                setStringError={setGeneralStringError}
                                clearErrors={() => {
                                    setGeneralStringError(undefined);
                                    setGeneralApolloError(undefined);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {
                                (!!generalApolloError || !!generalStringError) &&
                                <ErrorDisplay
                                    apolloError={generalApolloError}
                                    stringError={generalStringError}
                                />
                            }
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={{xs: 1, md: 2, lg: 3}} alignItems={'flex-start'}
                      sx={{mt: {xs: 2, sm: 0}, pl: {sm: 2}}}>
                    <Grid item xs={12} md={6} lg={7}>
                        <ErrorBoundary>
                            <EditableIncomeLines
                                payStubId={payStubId}
                                isEditable={!isFinalized}
                                reload={reloadIncomeItems}
                                reloaded={() => setReloadIncomeItems(false)}
                                incomeItemsUpdated={() => {
                                    setFinalizedErrorMessage(undefined);
                                    setReloadTotals(true);
                                    setCheckForCalculateNeeded(true);
                                }}
                                employeeId={employeeIdAsNumber}
                            />
                        </ErrorBoundary>
                    </Grid>
                    <Grid item xs={12} md={6} lg={5} alignSelf="flex-start" sx={{mb: 2}}>
                        {
                            deductionTypesLoading &&
                            <LoadingErrorDisplay loading={deductionTypesLoading}/>
                        }
                        {
                            !!deductionTypesData &&
                            <Deductions
                                isEditable={!isFinalized}
                                checkForCalculateNeeded={checkForCalculateNeeded}
                                checkForCalculateNeededDone={() => setCheckForCalculateNeeded(false)}
                                setDeductionsReady={setDeductionsReady}
                                calculatedDeductionsUpdated={() => {
                                    setReloadTotals(true);
                                    setReloadDeductions(true);
                                    setFinalizedErrorMessage(undefined);
                                }}
                                clearDeductions={clearDeductions}
                                employeeIsSet={!!employeeIdAsNumber}
                                deductionTypes={deductionTypesData.getDeductionTypes}
                                reload={reloadDeductions}
                                reloaded={() => setReloadDeductions(false)}
                                customDeductionsUpdated={() => {
                                    setFinalizedErrorMessage(undefined);
                                    setReloadTotals(true);
                                    setCheckForCalculateNeeded(true);
                                }}
                            />
                        }
                    </Grid>
                </Grid>

                <PayStubTotals
                    item1Label={"Current gross:"}
                    item2Label={"YTD gross:"}
                    item3Label={"Current net:"}
                    item4Label={"YTD net:"}
                    item1Amount={totalAmounts?.totalCurrentGross}
                    item2Amount={totalAmounts?.totalYtdGross}
                    item3Amount={totalAmounts?.totalCurrentNet}
                    item4Amount={totalAmounts?.totalYtdNet}
                    labelSx={{fontWeight: '600', marginRight: "8px"}}
                    amountSx={{fontWeight: '600', marginRight: "8px"}}
                />

                <Box style={{
                    backgroundColor: '#d4d4d4',
                    padding: '16px',
                    marginTop: 15,
                }}
                >
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Typography variant="h5">
                                Current Period Net Income:
                                <span> {totalAmounts?.totalCurrentNet}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <VacationSummaryDisplayEdit
                    payStubId={payStubId}
                    isEditable={!isFinalized}
                />
                <Grid container direction="row" sx={{mt: 4}}>
                    <Grid item xs={12} md={6} sx={{mb: 4}}>
                        {
                            (updatePayStubLoading || !!updatePayStubError) &&
                            <LoadingErrorDisplay
                                loading={updatePayStubLoading}
                                apolloError={updatePayStubError}
                            />
                        }
                        {!!employeeIdAsNumber && !!payStubId && !payStubYetToLoad && !payStubLoading &&
                            <PreviousPayStubDisplayEdit
                                employeeId={employeeIdAsNumber}
                                setPreviousPayStubId={updatePreviousPayStubId}
                                previousPayStubId={previousPayStubId}
                                payDate={payDate}
                                isEditable={!isFinalized}
                            />
                        }
                    </Grid>
                    <Grid item style={{flexGrow: 1}}/>
                    <Grid item>{previewOrDownloadButtons}</Grid>
                    <Grid item>
                        {!isFinalized &&
                            <Button
                                sx={{mt: 1, fontSize: '1.5rem'}}
                                variant={'contained'}
                                size={'large'}
                                disabled={finalizePayStubLoading}
                                onClick={() => finalizePayStubNow()}
                            >
                                Finalize
                            </Button>
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6} justifyContent="flex-end" sx={{mt: 2}}>
                        {
                            (finalizePayStubLoading || !!finalizePayStubError) &&
                            <LoadingErrorDisplay
                                loading={finalizePayStubLoading}
                                apolloError={finalizePayStubError}
                            />
                        }
                        {
                            !!finalizedErrorMessage &&
                            <PopUpErrorDisplay
                                stringError={finalizedErrorMessage}
                                onClose={() => setFinalizedErrorMessage(undefined)}
                                encouragedActionElement={showMyAccountButton ? myAccountButton : undefined}
                                dialogTitle={"Finalize message"}/>
                        }
                        {
                            !!finalizedSuccessMessage &&
                            <SuccessMessageDisplay messages={finalizedSuccessMessage}/>
                        }
                    </Grid>
                </Grid>
                {
                    showPayStubPreview && !!payStub.id &&
                    <PayStubPreview
                        showPreview={showPayStubPreview}
                        setShowPreview={setShowPayStubPreview}
                        payStubId={payStub.id}
                    />
                }
                {
                    isFinalized && !!payStubId &&
                    <CreateNextPayStubButton
                        previousPayStubId={payStubId}
                    />
                }
                {
                    showPayStubDownload && !!payStub.id &&
                    <PayStubDownload
                        showDownload={showPayStubDownload}
                        setShowDownload={setShowPayStubDownload}
                        payStubId={payStub.id}
                    />
                }
            </StyledPaper>
        </Container>
    </>

}
export default PayStubDisplayEdit;