import {gql} from "@apollo/client";

export const EMPLOYEE_FRAGMENT = gql`
    fragment employeeFields on Employee {
        id
        firstName
        lastName
        detailedName
        address1
        address2
        city
        province
        postalCode
        companyUserId
        eiExempt
        cppExempt
        federalClaimCode
        provincialClaimCode
        employerEiRatio
        payFrequency
        employmentProvince
        vacationPayPercent
        sin
        deleted
        accrueVacation
        dentalOfferedCode
    }

`;

const GET_EMPLOYEE = gql`
    query getEmployee($employeeId: Int!, $currentPayStubId: Int, $anonUserId: String!) {
        getEmployee(employeeId: $employeeId, currentPayStubId: $currentPayStubId, anonUserId: $anonUserId) {
            employee {
                ...employeeFields
            }
            hasPayStubs
        }
    }
    ${EMPLOYEE_FRAGMENT}
`;

export  default GET_EMPLOYEE;