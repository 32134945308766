import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import CloseDialogButton from "./CloseDialogButton";

type PropsType = {
    dialogTitle: string;
    stringError: string;
    onClose: () => void;
    encouragedActionElement?: React.ReactElement;
}

const PopUpErrorDisplay = (props: PropsType) => {
    const {dialogTitle, stringError, onClose, encouragedActionElement} = props;
    return <Dialog
        open={true}
    >

        <DialogTitle>
            <Typography variant={"h6"}>
                {dialogTitle}
            </Typography>
            <CloseDialogButton
                close={onClose}
            />
        </DialogTitle>
        <DialogContent>
            <Typography>
                {stringError}
            </Typography>
        </DialogContent>
        <DialogActions sx={{mr: 5}}>

            <Button
                variant={"contained"}
                onClick={onClose}
            >
                Close
            </Button>
            {encouragedActionElement}
        </DialogActions>
    </Dialog>
}
export default PopUpErrorDisplay;